import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { of, EMPTY } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AppState, AppStateUser } from 'src/app/services/app-state.service';
import { PlanService } from '../services/plan/plan.service';
import { TicketService } from '../services/ticket/ticket.service';

@Injectable({
	providedIn: 'root',
})
export class TicketDetailResolverService {
	constructor(
		private ticketService: TicketService,
		private planService: PlanService,
		private router: Router,
		private appState: AppState
	) {}

	public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const ticketId = route.paramMap.get('ticketId');
		const projectId = route.parent.paramMap.get('projectId');
		const planId = route.parent.paramMap.get('planId');
		const userData: AppStateUser = this.appState.user.value; //TODO - use observable version...
		const projectMemberId = userData.indices.get(projectId)?.projectMemberId;
		this.planService.getPlanList(projectId);
		if (projectMemberId) {
			this.ticketService.setupCustomFieldsApi(projectId, planId, projectMemberId);
		}

		//plan
		// - check the ticket list for the ticket
		// - if not there, load it into the secondary list, wait for it to load using the raw firebase thing to prove it exists
		//   - if raw firebase thing returns null, redirect to project list page
		//   - if not, return that ticket
		// - if there, grab it
		// - in either case, set that ticket to edit mode and return the "tickets in edit mode observable"
		// console.log('resolver start. ticket service loaded?', this.ticketService.loaded);
		//this check currently is basically a stand in for a ticket.service lazy load, should probably switch that out
		if (!this.ticketService.loaded) {
			//as it stands this will never succeed to be addressed by: https://mocasystems.atlassian.net/browse/TP-3138
			return this.ticketService.fetchTicketFromDetailView(projectId, planId, ticketId).pipe(
				take(1),
				switchMap((ticket) => {
					// console.log('resolver fetch emit', ticket);
					if (ticket) {
						return of(ticket);
					} else {
						// id not found
						this.router.navigate(['./']);
						return EMPTY;
					}
				})
			);
		} else {
			//if ticketList !has that ticket, redirect
			this.ticketService.selectTicket(ticketId);
			return this.ticketService.selectedTicket$.pipe(take(1));
		}
	}
}
