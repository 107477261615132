import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import * as appStrings from 'mobile/app/app-data/constants/strings';

@Component({
	selector: 'no-tickets-display',
	templateUrl: './no-tickets-display.component.html',
	styleUrls: ['./no-tickets-display.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoTicketsDisplayComponent {
	@Input() public numberOfPlanTickets: number;

	public strings = appStrings;
}
